import { apiUrl } from "./config";

export type WSMessage = {
  type: "notification" | "status";
  message: string;
  timestamp: string;
  id?: string;
  event?: string;
  filename?: string;
};

class ApiClient {
  private ws: WebSocket | null = null;
  private messageListeners: ((message: any) => void)[] = [];

  async request<T>(endpoint: string, options: RequestInit = {}): Promise<T> {
    const url = `${apiUrl}${endpoint}`;
    const response = await fetch(url, {
      ...options,
      credentials: "include",
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 401) {
      // Session is invalid or expired
      localStorage.removeItem("user");
      window.dispatchEvent(new Event("userLoggedOut"));
      throw new Error("Authentication failed. Please log in again.");
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  // GET request
  async get<T>(endpoint: string): Promise<T> {
    return this.request<T>(endpoint, { method: "GET" });
  }

  // POST request
  async post<T>(endpoint: string, data: any, isFormData = false): Promise<T> {
    const url = `${apiUrl}${endpoint}`;
    const headers: HeadersInit = {};

    if (!isFormData) {
      headers["Content-Type"] = "application/json";
    }

    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers,
      body: isFormData ? data : JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || `HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  async getMessage(): Promise<{ message: string }> {
    return this.get<{ message: string }>("/");
  }

  async summarisePDF(statementOfWork: File, specialConditions: File): Promise<{ message: string }> {
    const formData = new FormData();
    formData.append("statement_of_work", statementOfWork);
    formData.append("special_conditions", specialConditions);

    return this.post<{ message: string }>("/summarise", formData, true);
  }
  async createComplianceMatrix(statementOfWork: File, specialConditions: File): Promise<{ message: string }> {
    const formData = new FormData();
    formData.append("statement_of_work", statementOfWork);
    formData.append("special_conditions", specialConditions);

    return this.post<{ message: string }>("/create-compliance-matrix", formData, true);
  }

  async createResponseTemplate(statementOfWork: File, responseTemplate: File): Promise<{ message: string }> {
    const formData = new FormData();
    formData.append("statement_of_work", statementOfWork);
    formData.append("response_template", responseTemplate);

    return this.post<{ message: string }>("/create-response-template", formData, true);
  }

  async getUser(): Promise<any> {
    const response = await fetch(`${apiUrl}/user`, {
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error("Not authenticated");
    }
    return response.json();
  }

  async listDriveFiles(): Promise<{
    files: Array<{ id: string; name: string; mimeType: string; modifiedTime: string }>;
  }> {
    return this.get<{ files: Array<{ id: string; name: string; mimeType: string; modifiedTime: string }> }>(
      "/drive/files"
    );
  }

  connectWebSocket() {
    if (this.ws) {
      this.ws.close();
    }

    this.ws = new WebSocket(`${apiUrl.replace("http", "ws")}/ws`);

    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data) as WSMessage;
      this.messageListeners.forEach((listener) => listener(message));
    };

    this.ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    this.ws.onclose = (event) => {
      console.log("WebSocket closed:", event.code, event.reason);
    };
  }

  onMessage(callback: (message: any) => void) {
    this.messageListeners.push(callback);
    return () => {
      this.messageListeners = this.messageListeners.filter((listener) => listener !== callback);
    };
  }

  disconnectWebSocket() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }
}

export const apiClient = new ApiClient();

export type { ApiClient };
